import { graphql, useStaticQuery } from "gatsby";

export default function useSeo() {
  const {
    allSitePlugin: {
      edges: [
        {
          node: {
            pluginOptions: { lang, description },
          },
        },
      ],
    },
  } = useStaticQuery(graphql`
    query ManifestQuery {
      allSitePlugin(
        filter: { resolve: { glob: "**/gatsby-plugin-manifest" } }
      ) {
        edges {
          node {
            pluginOptions {
              lang
              description
            }
          }
        }
      }
    }
  `);

  return { lang, description };
}

import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import useSeo from "../hooks/useSeo";
import { StaticImage } from "gatsby-plugin-image";
import { Navbar, Nav, Container } from "react-bootstrap";
import { useLocation } from "@reach/router";

export default function Layout({
  pageTitle = "Torie Jee",
  fluid,
  mainClassName,
  children,
  containerRef,
}) {
  const { lang, description } = useSeo();
  const location = useLocation();

  return (
    <>
      <Helmet
        htmlAttributes={{ lang, class: "h-100" }}
        bodyAttributes={{ class: "h-100 d-flex flex-column" }}
      >
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
          integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
          crossorigin="anonymous"
        />
      </Helmet>
      <Navbar
        as="header"
        collapseOnSelect
        bg="light"
        variant="light"
        expand="md"
      >
        <Container>
          <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
            <StaticImage
              src="../images/icon.png"
              alt="Torie Jee"
              width={48}
              height={48}
              layout="fixed"
              className="rounded-lg"
              loading="eager"
            />
            <h1 className="ml-3">Torie Jee</h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="nav-menu" />
          <Navbar.Collapse id="nav-menu">
            <Nav as="nav" activeKey={location.pathname}>
              <Nav.Item>
                <Nav.Link as={Link} to="/" eventKey="/">
                  Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/projects" eventKey="/projects">
                  Projects
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/campaigns" eventKey="/campaigns">
                  Social Campaign Samples
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/resume" eventKey="/resume">
                  Resume
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="https://www.linkedin.com/in/toriejee/"
                  target="_blank"
                  rel="external noreferrer"
                >
                  LinkedIn
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="flex-grow-1 overflow-auto pt-4" ref={containerRef}>
        <Container as="main" fluid={fluid} className={mainClassName}>
          {children}
        </Container>
      </div>
    </>
  );
}
